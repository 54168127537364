.filled {
  & path {
    fill: var(--marketplaceColor);
  }
}

.HalfStar {
  & path {
    fill: var(--marketplaceColor);
  }
}
.emptyStar {
  & path {
    fill: var(--marketplaceColor);
  }
}
.starsDiv {
  & > svg {
    height: 23px;
    width: 23px;
    @media (max-width: 575px) {
      height: 18px;
      width: 18px;
    }
  }
}
